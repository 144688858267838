import React, { Fragment } from 'react'

// import { graphql, useStaticQuery } from 'gatsby'
// import RenderForm from 'gatsby-plugin-cloudofertas'

export default function FormIndex () {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       pagina: contentfulPagina(path: { eq: "/contato/" }) {
  //         path
  //         metaTitle
  //         metaDescription
  //         tags
  //       }
  //       cloudOfertasForm(name: {eq: "CONTATO"}) {
  //         id
  //         formTypeField {
  //           name
  //           type
  //           required
  //           style
  //           length
  //           label
  //           formLov {
  //             formLovData {
  //               id
  //               value
  //               createdDate
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  // const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <Fragment>
      <section id="contact" className="theme--quaternary mt-5 pb-2">
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-flex contact-img mb-4">
            </div>
            <div className="col-12 col-lg-6 pl-lg-4">
              <h2 className="mt-4 mb-2 theme-quinary">ENTRE EM CONTATO CONOSCO</h2>
              <div>
                { data.cloudOfertasForm.formTypeField && submitSuccess === false &&
                  <RenderForm
                    setSuccessSubmit={setSubmitSuccess}
                    formData={data.cloudOfertasForm}
                    placeholder={true}
                    containerStyle='container'
                    rowStyle='row'
                    inputStyle='custom-form d-flex flex-column col-lg-12 p-0 mt-3'
                    btnContainerStyle='custom-form col-12 p-0'
                    btnStyle='btn btn-block theme--primary btn-outline-#1A3797 theme-border-2 text-white my-3'
                    btnName='ENVIAR'
                  />
                }
                {submitSuccess &&
                  <div id="alertSucesso" className="alert alert-success mt-4" role="alert">
                    Obrigado por entrar em contato com a gente. Responderemos em até 48h.
                  </div>
                }
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </Fragment>
  )
}

/* eslint-disable array-callback-return */
/* eslint-disable space-before-blocks */
/* eslint-disable no-extend-native */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import 'moment/locale/pt-br'
import { GatsbyImage } from 'gatsby-plugin-image'

/* STYLES */
import '../offers-day/offers-day.css'

/* COMPONENTS */
import shuffle from '../../utils/shuffle'

export default function OffersDay ({ data }) {
  data = useStaticQuery(
    graphql`
      query {
        Ofertas: allCloudOfertasTabloide(
          sort: {fields: offer___order, order: DESC}
          filter: {status: {eq: "Publicado"}}
        ) {
          nodes {
            legalText
            startDate
            status
            offer {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
              ownBrand
              specialOffers
              order
              format
              id
              finishDate
              startDate
              category {
                name
                id
              }
            }
          }
        }
      }
    `
  )
  const _ofertasGeral = []
  data.Ofertas.nodes.map(
    edge => edge.offer.map(off => {
      if (off.format === 4) {
        _ofertasGeral.push(off)
      }
    })
  )
  const _ofertas = shuffle(_ofertasGeral)

  const validStyle = {
    zIndex: 1,
    position: 'absolute',
    bottom: '-2px',
    textAlign: 'center',
    width: '100%'
  }

  const validTextStyle = {
    background: '#fff',
    color: '#333',
    padding: '3px 5px 1px',
    opacity: '0.8'
  }

  return (
    <Fragment>
      <section id="offers-day">
        <div className="container">
          <div className="row m-0">
            <div id="products" className="col-12 my-3 my-lg-4 py-2 mx-auto">
              <h3 className="ml-3 ml-md-0"><span>+</span> Confira Nossas Ofertas</h3>
              <div id="offer-margin" className="row row-cols-2 row-cols-lg-4 row-cols-md-4" style={{ marginLeft: 0 }}>
                {_ofertas.slice(0, 4).map((ofertas, index) => {
                  return (
                    <div key={index}>
                      {ofertas !== undefined
                        ? <div className="hvr-grow element-item">
                          <GatsbyImage style={{ width: '100%', maxHeight: '250px' }}
                            image={ofertas.image.childImageSharp.gatsbyImageData}
                            alt={`Oferta de ${ofertas.category.name}`} />
                          <div style={validStyle}>
                            <small style={ofertas.category.name === 'Banner' ? { display: 'none' } : validTextStyle}>Válida até {moment(ofertas.finishDate).format('DD/MM/YYYY')}</small>
                          </div>
                        </div>
                        : ''
                      }
                    </div>
                  )
                }
                )}
              </div>
              <Link to="/ofertas/" className="d-none btn-more-offers d-lg-flex w-100 mt-4 p-3 btn justify-content-center theme-border-2 text-white">Veja mais ofertas da DrogaLeste. CLIQUE AQUI</Link>
            </div>
          </div>
        </div>
        <div className="my-3 theme--secundary d-lg-none">
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center py-4">
              <div className="mx-2">
                <h3 className="text-center text-white m-auto">VEJA MAIS OFERTAS</h3>
                <Link to="/ofertas/" alt='' className="btn theme-secundary px-5 py-2">CLIQUE AQUI</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import { GatsbyImage } from 'gatsby-plugin-image'

/* STYLES */
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default function BannerHome ({ banners }) {
  return (
    <Carousel showArrows={true} showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay={true}>
      {
        banners.nodes.map((banner, index) => {
          return (
            <Link to={banner.url} key={index}>
              <GatsbyImage
                image={banner.banner.childImageSharp.gatsbyImageData}
                alt="Banner Home Desktop"
                className='d-none d-lg-block w-100'
              />
              <GatsbyImage
                image={banner.mobileBanner.childImageSharp.gatsbyImageData}
                alt="Banner Home Mobile"
                className='d-block d-lg-none w-100'
              />
            </Link>
          )
        })
      }
    </Carousel>
  )
}

import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Pagina from '../components/Pagina'
import '../assets/css/common.css'
import PageIndex from '../components/page-index/page-index'

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
    }
  `
  )
  console.log('')

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <PageIndex />
    </Fragment>
  )
}
export default IndexPage

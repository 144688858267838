import React, { Fragment } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

/* COMPONENTS */
import BannerHome from '../../components/carousel/carousel'
// import BannerHomeMobile from '../../components/carousel/carousel-mobile'
import FormIndex from '../../components/form-index/form-index'
import OffersDay from '../../components/offers-day/offers-day'

import './page-index.css'
// import BannerCovid from '../banner-covid/banner-covid'

export function PageIndex () {
  const data = useStaticQuery(
    graphql`{
  Ofertas: allCloudOfertasTabloide(
    sort: {fields: offer___order, order: DESC}
    filter: {status: {eq: "Publicado"}}
  ) {
    nodes {
      status
    }
  }
  banners: allCloudOfertasBanner {
    nodes {
      url
      active
      target
      title
      mobileBanner {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 785, height: 418, layout: CONSTRAINED)
        }
      }
      banner {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`
  )

  return (
    <Fragment>
      {/* <BannerCovid /> */}
      <div className="modal fade show" id="modal" tabIndex="-1" role="dialog" aria-labelledby="modalCovid" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
        </div>
      </div>
      <section id="icon-main" className="d-lg-none d-block">
        <div className="container">
          <ul className="row justify-content-center flex-wrap list-inline py-2">
            <li><Link to="/lojas/" title="Ir para o localizador de lojas" className="btn text-white"><span className="sr-only">LOJAS</span></Link></li>
            <li><Link to="/lojas/" title="Ir para o localizador de lojas" className="btn text-white"><span className="sr-only">DISK ENTREGA</span></Link></li>
            <li><Link to="/ofertas/" className="btn text-white" title="Ir para a página de ofertas"><span className="sr-only">OFERTAS</span></Link></li>
            <li><Link to="/convenios-parcerias/" className="btn text-white" title="Ir para a página de convênios"><span className="sr-only">CONVÊNIOS</span></Link></li>
          </ul>
        </div>
      </section>
      <div>
        <BannerHome banners={data.banners} />
      </div>
      {data.Ofertas.nodes.length > 0
        ? <OffersDay />
        : ''
      }
      <section className="d-none d-tp-flex">
        <div className="container">
          <div className="row">
            <div id="offers-day-desktop" className="col-lg-12 d-flex justify-content-center align-items-center">
              <div id="pin-loja" className="col-lg-4 align-self-center">
                <div className="pin-loja"></div>
              </div>
              <div id="textOfferStore" className="col-lg-4 align-self-center">
                <p className="theme-quaternary"><b>ENCONTRE A </b><span className="theme-senary"><b>LOJA</b></span><b> MAIS</b><br/><span className="theme-senary"><b>PRÓXIMA DE VOCÊ</b></span><b>!</b><br/></p>
              </div>
              <div className="col-lg-4 d-flex justify-content-center">
                <Link to="/lojas/" className="d-flex flex-column justify-content-center btn theme-border-2 theme--quinary text-white" title="Buscar loja">CLIQUE AQUI</Link>
              </div>
            </div>
            <div className="col-4 text-white text-center contact-us">
              <div></div>
              <h3 className="font-weight-bold">Disk Entrega</h3>
              <p>A Nova Farma vai até você</p>
              <Link to="/lojas/" className="w-75 btn theme-border-2 theme-secundary bg-white" title="Solicite agora a entrega em domicílio">SOLICITE AGORA</Link>
            </div>
            <div className="col-4 text-white text-center offers">
              <div></div>
              <h3 className="font-weight-bold">Ofertas</h3>
              <p>As melhores marcas por <br/> preços incríveis</p>
              <Link to="/ofertas/" className="w-75 btn theme-border-2 theme-secundary bg-white" title="Ver ofertas da loja">VER OFERTAS</Link>
            </div>
            <div className="col-4 text-white text-center covenant">
              <div></div>
              <h3 className="font-weight-bold">Convênio</h3>
              <p>A Nova Farma atende <br/> diversos planos e convênios</p>
              <Link to="/convenios-parcerias/" className="w-75 btn theme-border-2 theme-secundary bg-white" title="seja um conveniado">SEJA UM CONVENIADO</Link>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-2">
        <div id="parallax" className="d-none d-lg-flex mt-5 bgParallax" data-speed="100" style={{ backgroundPosition: '2% -11.128px' }}></div>
        <div className="container">
          <div className="row">
            <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
              <h2 className="d-flex flex-column flex-sm-row my-4 my-lg-5 theme-quinary">SOBRE A NOVAFARMA</h2>
              <p>
              A Nova Farma é uma marca jovem e moderna, mas que carrega quase 30 anos de conhecimento no varejo brasileiro. Temos obsessão pela excelência em todos os detalhes para proporcionar a melhor a melhor experiência de compra possível a nossos clientes. Temos a proposta de resgatar o atendimento humanizado das drogarias de bairro, mas com lojas modernas e agradáveis que possuem um mix de produtos completo, oferecendo agilidade e conveniência. Experimente o novo, experimente a Nova Farma!</p>
              <Link to="/quem-somos/" title="Leia mais sobre a nossa empresa" className="d-flex justify-content-center align-items-center mt-sm-4 btn theme-border-2 theme--quinary text-white w-100">LEIA MAIS SOBRE A NOSSA EMPRESA</Link>
            </div>
          </div>
        </div>
      </section>
      <FormIndex />
    </Fragment>
  )
}

export default PageIndex
